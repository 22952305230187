<template>
	<div class="page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<el-form ref="form" :inline="true"  :model="search_key" prop="">
				 <div  style="position: absolute;right:20px;z-index: 999;">
				 	<el-button  type="primary" @click="dialogExportVisible=true">导出</el-button> 
				 </div>
		    <el-form-item label="">
		        <el-input v-model="search_key.keyword" placeholder="请输入编码/名称搜索" style="width:200px;"></el-input>
		    </el-form-item>
		    <el-button type="primary" @click="getData()">查询</el-button>
		</el-form>
		<el-table :data="tableData" :span-method="objectSpanMethod" border>
			<el-table-column prop="mtl_code" label="物料编码">
			</el-table-column>
			<el-table-column prop="name" label="物料名称">
			</el-table-column>
			<el-table-column prop="cate_name" label="物料分类">
			</el-table-column>
			<el-table-column prop="card_name" label="单据类型">
			</el-table-column>
			<el-table-column prop="receipt_number" label="单据编号">
			</el-table-column>
			<el-table-column prop="create_time" label="操作时间">
			</el-table-column>
		</el-table>
		
		<!-- 导出弹窗 -->
		  <el-dialog title="导出筛选" :visible.sync="dialogExportVisible" width="30%">
		      <el-form   prop="">
		          <el-form-item label="日期范围" prop="date">
		              <el-date-picker
		                  v-model="timeSpan"
		                  type="datetimerange"
		                  range-separator="至"
		                  start-placeholder="开始日期"
		                  end-placeholder="结束日期"
		                  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
		              </el-date-picker>
		          </el-form-item>
		      </el-form>
		  <div slot="footer" class="dialog-footer">
		      <el-button type="primary" @click="handleExport()">导出</el-button>
		  </div>
		  </el-dialog>
	</div>
</template>
<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				tableData: [],
				rowspanArr:[],
				search_key:{
					keyword:''
				},
				spanArr:[],
				timeSpan:'',
				dialogExportVisible:false
			}
		},
		created() {
			this.getData()
		},
		mounted() {

		},
		methods: {
			getData() {
				this.$api.get('CseReport/record', {
					keyword:this.search_key.keyword
				}, res => {
					if (res.code == 200) {
						this.tableData = res.data.list
						this.rowspanArr =  res.data.rowspan
						this.getSpanArr()
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			handleClick(val) {
				this.getData()
			},
			handleExport(){
				location.href = '/api/admin/CseReport/exportRecord?timeSpan='+this.timeSpan
			},
			
			 // 要合并的数组
			getSpanArr(data) {
			      this.spanArr = []
			      this.tableData.forEach((item, index) => {
			        if (index === 0) {
			          this.spanArr.push(1)
			          this.pos = 0 // 指针
			        } else {
			          if (item['id'] && item['id'] === this.tableData[index - 1]['id']) { //当前项和前一项比较 
			            this.spanArr[this.pos] += 1; //相同值第一个出现的位置，统计需要合并多少行,指针往后移动一位
			            this.spanArr.push(0); //新增一个被合并行 0表示要合并
			          } else {
			            this.spanArr.push(1); //否则不合并
			            this.pos = index; //指向位移
			          }
			        }
			      })
			    },
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				
				if (columnIndex <= 2) {
					 const _row = this.spanArr[rowIndex];
					  const _col = _row > 0 ? 1 : 0;
					return {
						  rowspan: _row, // rowspan: [2, 0, 2, 0]
						  colspan: _col, // colspan: [1, 0, 1, 0]
						};

				}
			}	
		}
	}
</script>
<style scoped lang="scss">
</style>
